import React, { useEffect, useRef } from 'react'
import { graphql, navigate } from 'gatsby'
import { RichText } from 'prismic-reactjs'
import RollingBanner from '../components/sections/RollingBanner'
// import { GlobalEventBus } from '../helpers/eventBus'
import linkResolver from '../utils/linkResolver'
import Seo from '../components/Seo'

const About = ({ data }) => {
	const pageData = data.prismicAbout.data
	const containerEl = useRef(null)

	useEffect(() => {
		const handleKeydown = e => {
			if (e.code === 'Escape') {
				navigate('/')
			}
		}
		window.addEventListener('keydown', handleKeydown)
		return () => {
			window.removeEventListener('keydown', handleKeydown)
		}
	}, [])

	return (
		<main className="About" ref={containerEl}>
			<Seo
				title="About"
				description={pageData.meta_description.text}
			/>
			<ul className="About__HeaderTexts">
				{pageData.header_texts.map((text, index) => (
					<li className="About__HeaderText" key={`about-header-texts-${index}`}>
						<RichText render={text.text.richText}/>
					</li>
				))}
			</ul>
			<div className="About__BannerContainer">
				<RollingBanner inlineSvg={pageData.inline_svg.text} duration={10} />
			</div>
			<div className="About__FooterBigText">
				<RichText render={pageData.footer_big_text.richText}/>
			</div>
			<div className="About__FooterText">
				<RichText render={pageData.footer_text.richText} linkResolver={linkResolver}/>
			</div>
		</main>
	)
}

export default About

export const query = graphql`
	query About {
		prismicAbout {
			data {
				meta_description {
        	text
      	}
				header_texts {
					text {
						richText
					}
				}
				inline_svg {
					text
				}
				footer_big_text {
					richText
				}
				footer_text {
					richText
				}
			}
		}
		prismicLoader {
			...Loader
		}
	}
`